export class PracticeModel {
  constructor({
      practiceId,
      addPracticeDate = "", // 연습 추가 날짜 (YYYY-MM-DD 형식)
      title = "", // 연습 제목
      content = "", // 연습 내용
      userId = "", // 연습을 생성한 사용자 ID
  }) {
      this.practiceId = practiceId;
      this.addPracticeDate = addPracticeDate;
      this.title = title;
      this.content = content;
      this.userId = userId;
  }

  static fromJson(jsonData) {
      return new PracticeModel({
        practiceId: jsonData.practiceId,
        addPracticeDate: jsonData.addPracticeDate,
        title: jsonData.title,
        content: jsonData.content,
        userId: jsonData.userId
      });
  }

  toJson() {
      return {
        practiceId: this.practiceId,
        addPracticeDate: this.addPracticeDate,
        title: this.title,
        content: this.content,
        userId: this.userId
      };
  }
}