import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../config/firebaseConfig';
import { UserModel } from '../models/UserModel';
import sha256 from 'crypto-js/sha256';

// 고정된 Salt 값
const SALT = 'fixed_salt_value';

/**
 * 이메일과 비밀번호로 새 사용자를 등록하고 Firestore에 사용자 정보를 저장합니다.
 * @param {string} email - 사용자 이메일
 * @returns {Promise<{ uid: string, password: string } | null>} - 생성된 사용자 uid와 해시된 비밀번호 객체 또는 null
 */
export const registerUserInService = async (email) => {
    const hashedPassword = sha256(email + SALT).toString(); // 고정된 Salt로 해시 생성

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, hashedPassword);
        const firebaseUser = userCredential.user;

        return {
            userId: firebaseUser.uid,
            password: hashedPassword
        };
    } catch (error) {
        console.error('Error registering user:', error);
        return null;
    }
};

/**
 * 이메일과 비밀번호로 사용자를 로그인합니다.
 * @param {string} email - 사용자 이메일
 * @returns {Promise<{ uid: string, password: string } | null>} - 로그인된 사용자 uid 또는 null
 */
export const loginUserInService = async (email) => {
    const hashedPassword = sha256(email + SALT).toString(); // 고정된 Salt로 해시 생성

    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, hashedPassword);
        const firebaseUser = userCredential.user;

        return {
            userId: firebaseUser.uid,
            password: hashedPassword
        };
    } catch (error) {
        console.error('Error logging in user:', error);
        return null;
    }
};

/**
 * 현재 로그인된 사용자를 로그아웃합니다.
 * @returns {Promise<void>} - 성공 시 void, 실패 시 null
 */
export const logoutUserInService = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error('Error logging out user:', error);
    }
};