    import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
    import { db } from '../config/firebaseConfig';
    import { FileModel } from '../models/FileModel';

    // files 컬렉션 이름 상수
    const FILES_COLLECTION = 'files';

    /**
     * 파일을 생성하고 Firestore에 저장합니다.
     * @param {FileModel} file - 생성할 파일 객체
     * @returns {Promise<void>}
     */
    export const createFileInService = async (file) => {
        try {
            const fileDocRef = doc(db, FILES_COLLECTION, file.fileId);
            await setDoc(fileDocRef, file.toJson());
        } catch (error) {
            console.error('파일 생성 오류:', error);
            throw error;
        }
    };

    /**
     * 특정 fileId로 Firestore에서 파일 데이터를 가져옵니다.
     * @param {string} fileId - 가져올 파일의 문서 ID
     * @returns {Promise<FileModel|null>}
     */
    export const getFileInService = async (fileId) => {
        try {
            const fileDocRef = doc(db, FILES_COLLECTION, fileId);
            const fileDoc = await getDoc(fileDocRef);

            if (fileDoc.exists()) {
                return FileModel.fromJson({ fileId, ...fileDoc.data() });
            } else {
                console.log('해당 문서가 존재하지 않습니다!');
                return null;
            }
        } catch (error) {
            console.error('파일 조회 오류:', error);
            throw error;
        }
    };

    /**
     * 특정 fileId로 Firestore에서 파일 데이터를 업데이트합니다.
     * @param {string} fileId - 업데이트할 파일의 문서 ID
     * @param {Object} updatedData - 업데이트할 데이터 객체
     * @returns {Promise<void>}
     */
    export const updateFileInService = async (fileId, updatedData) => {
        try {
            const fileDocRef = doc(db, FILES_COLLECTION, fileId);
            await updateDoc(fileDocRef, updatedData);
        } catch (error) {
            console.error('파일 업데이트 오류:', error);
            throw error;
        }
    };

    /**
     * 특정 fileId로 Firestore에서 파일 데이터를 삭제합니다.
     * @param {string} fileId - 삭제할 파일의 문서 ID
     * @returns {Promise<void>}
     */
    export const deleteFileInService = async (fileId) => {
        try {
            const fileDocRef = doc(db, FILES_COLLECTION, fileId);
            await deleteDoc(fileDocRef);
        } catch (error) {
            console.error('파일 삭제 오류:', error);
            throw error;
        }
    };

    /**
     * 특정 userId에 연관된 파일들을 가져옵니다.
     * @param {string} userId - 파일을 필터링할 userId
     * @returns {Promise<FileModel[]>}
     */
    export const getFilesByUserId = async (userId) => {
        try {
            const filesQuery = query(collection(db, FILES_COLLECTION), where('userId', '==', userId));
            const querySnapshot = await getDocs(filesQuery);

            return querySnapshot.docs.map(doc => FileModel.fromJson({ fileId: doc.id, ...doc.data() }));
        } catch (error) {
            console.error('userId로 파일 조회 오류:', error);
            throw error;
        }
    };

    /**
     * 특정 partnerId에 연관된 파일들을 가져옵니다.
     * @param {string} partnerId - 파일을 필터링할 partnerId
     * @returns {Promise<FileModel[]>}
     */
    export const getFilesByPartnerId = async (partnerId) => {
        try {
            const filesQuery = query(collection(db, FILES_COLLECTION), where('partnerId', '==', partnerId));
            const querySnapshot = await getDocs(filesQuery);

            return querySnapshot.docs.map(doc => FileModel.fromJson({ fileId: doc.id, ...doc.data() }));
        } catch (error) {
            console.error('partnerId로 파일 조회 오류:', error);
            throw error;
        }
    };