import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import Logotype from "../../assets/images/Logotype.png";
import Frame from "../../assets/images/Frame.png";
import KakaoLogin from "../../assets/images/kakaoLogin.png";
import ReactGA from "react-ga4";

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_REST_API_KEY;
const REDIRECT_URI = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}/signup`;

function Login() {
  const navigate = useNavigate();

  // 카카오 로그인 버튼 클릭 시 GA 이벤트 기록
  const loginWithKakao = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Kakao Login Button",
      label: "Login Page",
    });
    console.log("GA Event Sent: User clicked Kakao Login button");

    // if (process.env.NODE_ENV === "development") {
    //   console.log("Development environment: Using dummy email for login.");
    //   const dummyEmail = "lim9660@kakao.com";

    //   // 로컬 환경에서 회원가입 페이지로 이동하면서 더미 이메일 전달
    //   navigate("/signup", { state: { email: dummyEmail } });
    //   return;
    // }

    // 배포 환경: 카카오 로그인 페이지로 리다이렉트
    const url = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&response_type=code`;
    window.location.href = url;
  };

  return (
    <div className={styles.container}>
      <div className={styles.customContainer}>
        <p className={styles.loginText}>영업 연습을 통해 역량을 쌓아보세요</p>

        <div className={styles.imageContainer}>
          <ResponsiveContainer
            className={styles.Frameimg}
            width={70}
            height={70}
          >
            <img className={styles.Frame} src={Frame} alt="Roleplay Icon" />
          </ResponsiveContainer>
          <ResponsiveContainer
            className={styles.Logotypeimg}
            width={340}
            height={64}
          >
            <img
              className={styles.Logotype}
              src={Logotype}
              alt="Roleplay Icon"
            />
          </ResponsiveContainer>
        </div>

        <ResponsiveContainer
          className={styles.loginButtonimg}
          width={442}
          height={52}
        >
          <button className={styles.loginButton} onClick={loginWithKakao}>
            <img
              className={styles.KakaoLogin}
              src={KakaoLogin}
              alt="Kakao Icon"
            />
          </button>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Login;
