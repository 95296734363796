import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext"; // UserContext 가져오기
import Loading from "../Loading/Loading"; // 로딩 상태를 위한 컴포넌트
import { ResponsiveContainer } from "../../components/ResponsiveContainer"; // 반응형 컨테이너
import styles from "./AddMember.module.css"; // 스타일 파일 가져오기
import ReactGA from "react-ga4"; // GA 라이브러리 추가

function AddMember() {
  const [email, setEmail] = useState(""); // 이메일 입력 상태
  const [name, setName] = useState(""); // 이름 입력 상태
  const [error, setError] = useState(""); // 오류 메시지 상태
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate 훅
  const { addMember } = useUser(); // UserContext의 addMember 메서드 사용

  /**
   * 이메일 유효성 검사
   * @param {string} email - 사용자 입력 이메일
   * @returns {boolean} - 이메일 형식이 유효한 경우 true 반환
   */
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 이메일 정규식
    return emailRegex.test(email);
  };

  /**
   * 팀 멤버 추가 처리
   */
  const handleAddMember = async () => {
    // 이메일 형식이 올바르지 않으면 오류 표시
    if (!isEmailValid(email)) {
      setError("이메일 형식이 올바르지 않습니다.");
      return;
    }

    // 이메일이나 이름이 비어 있으면 오류 표시
    if (!email || !name) {
      setError("모든 필드를 입력해주세요.");
      return;
    }

    // 기존 오류 메시지 제거 및 로딩 상태 활성화
    setError("");
    setIsLoading(true);

    try {
      // UserContext의 addMember 호출
      const newMember = await addMember(email, name);

      // 성공적으로 추가되었을 경우
      ReactGA.event({
        category: "User",
        action: "Add Member Success",
        label: "AddMember Page",
      });
      console.log("GA Event Sent: Add Member Success");

      console.log("팀원이 성공적으로 추가되었습니다:", newMember);
      navigate("/membercomplete", { state: { name } }); // 완료 페이지로 이동
    } catch (err) {
      // 오류 발생 시 에러 메시지 설정 및 GA 이벤트 전송
      console.error("팀원을 추가할 수 없습니다:", err);
      setError(err.message || "팀원의 이메일을 다시 한번 확인해주세요.");

      ReactGA.event({
        category: "Error",
        action: "Add Member Failed",
        label: "AddMember Page",
      });
      console.log("GA Event Sent: Add Member Failed");
    } finally {
      setIsLoading(false); // 로딩 상태 비활성화
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading /> // 로딩 중일 때 로딩 컴포넌트 렌더링
      ) : (
        <div className={styles.page}>
          <div className={styles.mainContainer}>
            <ResponsiveContainer width={880}>
              <p className={styles.TitleText}>추가할 팀원을</p>
              <p className={styles.TitleText2}>적어주세요</p>

              {/* 이메일 입력 필드 */}
              <div className={styles.inputcontainer}>
                <p className={styles.pText}>팀원의 가입 이메일</p>
                <input
                  type="email"
                  placeholder="이메일을 적어주세요"
                  className={styles.Input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* 오류 메시지 표시 */}
              {error && <p className={styles.errorText}>{error}</p>}

              {/* 이름 입력 필드 */}
              <div className={styles.inputcontainer2}>
                <p className={styles.pText}>팀원 이름</p>
                <input
                  type="text"
                  placeholder="이름을 적어주세요"
                  className={styles.Input}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* 추가하기 버튼 */}
              <button
                className={`${styles.addButton} ${
                  email && name ? styles.enabled : ""
                }`}
                onClick={handleAddMember}
                disabled={!email || !name}
              >
                추가하기
              </button>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddMember;
