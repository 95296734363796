import React from 'react';
import styles from './AlertCard.module.css';

const AlertCard = ({ icon, title, description, onConfirm }) => {
  return (
    <div className={styles.container}>
      <img src={icon} className={styles.icon}/>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <button className={styles.confirmButton} onClick={onConfirm}>
        확인
      </button>
    </div>
  );
};

export default AlertCard;