import {doc,setDoc,getDoc,updateDoc,deleteDoc,collection,query,where,getDocs
} from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { PracticeModel } from '../models/PracticeModel';

// practices 컬렉션 이름 상수
const PRACTICES_COLLECTION = 'practices';

/**
* 연습 데이터를 생성하고 Firestore에 저장합니다.
* @param {PracticeModel} practice - 생성할 연습 데이터 객체
* @returns {Promise<void>}
*/
export const createPracticeInService = async (practice) => {
    try {
      const practiceDocRef = doc(db, PRACTICES_COLLECTION, practice.practiceId);
      await setDoc(practiceDocRef, {
        ...practice.toJson(),
        userId: practice.userId, // userId 포함
      });
    } catch (error) {
      console.error("연습 데이터 생성 오류:", error);
      throw error;
    }
  };

/**
* 특정 practiceId로 Firestore에서 연습 데이터를 가져옵니다.
* @param {string} practiceId - 가져올 연습 데이터의 문서 ID
* @returns {Promise<PracticeModel|null>}
*/
export const getPracticeInService = async (practiceId) => {
  try {
      const practiceDocRef = doc(db, PRACTICES_COLLECTION, practiceId);
      const practiceDoc = await getDoc(practiceDocRef);

      if (practiceDoc.exists()) {
          return PracticeModel.fromJson({ practiceId, ...practiceDoc.data() });
      } else {
          console.log('해당 연습 데이터가 존재하지 않습니다!');
          return null;
      }
  } catch (error) {
      console.error('연습 데이터 조회 오류:', error);
      throw error;
  }
};

/**
* 특정 practiceId로 Firestore에서 연습 데이터를 업데이트합니다.
* @param {string} practiceId - 업데이트할 연습 데이터의 문서 ID
* @param {Object} updatedData - 업데이트할 데이터 객체
* @returns {Promise<void>}
*/
export const updatePracticeInService = async (practiceId, updatedData) => {
  try {
      const practiceDocRef = doc(db, PRACTICES_COLLECTION, practiceId);
      await updateDoc(practiceDocRef, updatedData);
  } catch (error) {
      console.error('연습 데이터 업데이트 오류:', error);
      throw error;
  }
};

/**
* 특정 practiceId로 Firestore에서 연습 데이터를 삭제합니다.
* @param {string} practiceId - 삭제할 연습 데이터의 문서 ID
* @returns {Promise<void>}
*/
export const deletePracticeInService = async (practiceId) => {
  try {
      const practiceDocRef = doc(db, PRACTICES_COLLECTION, practiceId);
      await deleteDoc(practiceDocRef);
  } catch (error) {
      console.error('연습 데이터 삭제 오류:', error);
      throw error;
  }
};

/**
* 특정 userId에 연관된 연습 데이터를 가져옵니다.
* @param {string} userId - 연습 데이터를 필터링할 userId
* @returns {Promise<PracticeModel[]>}
*/
export const getPracticesByUserId = async (userId) => {
  try {
      const practicesQuery = query(
          collection(db, PRACTICES_COLLECTION),
          where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(practicesQuery);

      return querySnapshot.docs.map((doc) =>
          PracticeModel.fromJson({ practiceId: doc.id, ...doc.data() })
      );
  } catch (error) {
      console.error('userId로 연습 데이터 조회 오류:', error);
      throw error;
  }
};
