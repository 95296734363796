import React, { useState } from "react";
import styles from "./PhoneNumber.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";

const PhoneNumber = () => {
  const { userData, updateUser } = useUser();
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const handlePhoneNumberSubmit = async () => {
    if (phoneNumber.trim() === "") {
      alert("전화번호를 입력하세요.");
      return;
    }

    try {
      await updateUser({ phoneNumber }); // Firestore에 전화번호 업데이트
      navigate("/logincomplete", { state: { userId: userData.userId } });
    } catch (error) {
      console.error("Error updating phone number:", error);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
        <ResponsiveContainer width={880}>
          <h2 className={styles.TitleText}>전화번호를 입력해주세요</h2>

          <div className={styles.inputContainer}>
            <p className={styles.p2Text}>전화번호</p>
            <input
              type="text"
              className={styles.Input}
              placeholder="전화번호를 입력하세요"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <button
            className={styles.addButton}
            onClick={handlePhoneNumberSubmit}
          >
            다음
          </button>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PhoneNumber;
