// CustomTimePicker.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './CustomTimePicker.module.css'

// 시간 선택 시 "시"만 선택 가능한 TimePicker
const CustomTimePicker = ({ selectedTime, onChange }) => {
  const handleChange = (time) => {
    if (time) {
      onChange(time);
    }
  };

  return (
    <DatePicker
      className={styles.CustomTimePicker}
      selected={selectedTime}
      onChange={handleChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60} // 60분 간격으로 시간 선택
      timeFormat="HH'시'"
      dateFormat="HH'시'" // 시간 형식 설정
      placeholderText="시간을 선택해주세요"
    />
  );
};

export default CustomTimePicker;