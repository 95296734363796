import React, { useRef, useState, useEffect } from "react";
import styles from "./FileList.module.css";
import Tooltip from "../Tooltip/Tooltip";

const FileList = ({ files, onFileClick }) => {
  // Sort files by latest practice date
  const sortedFiles = files.sort(
    (a, b) => new Date(b.practiceDateTime) - new Date(a.practiceDateTime)
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const firstFileRef = useRef(null);

  useEffect(() => {
    // 첫 번째 파일이 있고, 상태가 "complete"가 아닌 경우에만 툴팁 표시
    if (
      files.length > 0 &&
      files[0].name === "#1" &&
      files[0].status !== "complete"
    ) {
      setShowTooltip(true);
    }
  }, [files]);

  return (
    <div>
      <table className={styles.fileTable}>
        <thead>
          <tr>
            <th>예약 번호</th>
            <th>연습 시간</th>
            <th>상태</th>
            <th>대화 성향</th>
            <th>상담 목적</th>
          </tr>
        </thead>
        <tbody>
          {sortedFiles.length >= 1 ? (
            sortedFiles.map((file) => (
              <tr
                key={file.fileId}
                className={styles.fileItem}
                onClick={() => onFileClick(file)}
              >
                <td ref={file.name === "#1" ? firstFileRef : null} // 첫 번째 파일에 ref 연결
                >{file.name}</td>
                <td>
                  <p>
                    {new Date(file.practiceDateTime).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })}
                  </p>
                  <p>
                    {new Date(file.practiceDateTime).toLocaleTimeString("ko-KR", {
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                  </p>
                </td>
                <td>
                  <span className={`${styles.status} ${styles[file.status]}`}>
                    {file.status === "notStarted" ? "시작 전" :
                      file.status === "inProgress" ? "진행 중" :
                        file.status === "complete" ? "완료" : "중단"}
                  </span>
                </td>
                <td>
                  <span className={`${styles.partnerStyle} ${styles[file.partnerStyle === "긍정적" ? "positive" :
                    file.partnerStyle === "부정적" ? "negative" : "random"]}`}>
                    {file.partnerStyle}
                  </span>
                </td>
                <td>{file.partnerPurpose || "랜덤"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className={styles.emptyContainer}>
                <p className={styles.emptyTitle}>연습이 없어요</p>
                <p className={styles.emptyDescription}>
                  첫 번째 연습을 예약해보세요
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showTooltip && firstFileRef.current && (
        <Tooltip message="상담 목록을 클릭해보세요" targetRef={firstFileRef} />
      )}
    </div>
  );
};

export default FileList;
