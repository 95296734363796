import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './AdminComplete.module.css';
import AlertCard from '../../components/AlertCard/AlertCard';
import CheckImage from '../../assets/images/complete/Check.png';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

const AdminComplete = () => {
    const navigate = useNavigate();

    const handleConfirmClick = () => {
        navigate('/');
    };

    return (
        <div className={styles.page}>
            <div className={styles.mainContainer}>
                <ResponsiveContainer width={880} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AlertCard icon={CheckImage} title={'파트너 등록 완료!'} description={'파트너가 등록되었어요'} onConfirm={handleConfirmClick} />
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default AdminComplete;