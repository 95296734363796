import React from 'react'
import styles from './Purchase.module.css';
import ExclamationMark from '../../assets/images/ExclamationMark.png';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

function Purchase() {
  return (
    <div className={styles.container}>
      <div className={styles.customContainer}>
        <div className={styles.textcontainer}>
          <p className={styles.p1Text}>내 플랜 조회</p>
          <div className={styles.textcontainer2}>
            <p className={styles.p2Text}>현재 내 플랜</p>
            <ResponsiveContainer className={styles.customButton}>
              <p className={styles.customButtonText}>월간 구독제</p>
            </ResponsiveContainer>
          </div>
          <p className={styles.p3Text}>구독제 구매 및 가격 문의는 인톡 고객센터를 이용해주세요!</p>
          <ResponsiveContainer className={styles.customButton2}>
            <div className={styles.buttonContent}>
              <img src={ExclamationMark} alt="고객센터 아이콘" className={styles.customButtonIcon} width={24} height={24} />
              <p className={styles.customButtonText2}>고객센터는 좌측 메뉴에 찾을 수 있습니다.</p>
            </div>
          </ResponsiveContainer>
          <ResponsiveContainer height={43} width={120} className={styles.customOkButton} onClick={() => window.open('http://pf.kakao.com/_ChIBxj')}>

            <p className={styles.customOkButtonText}>문의하기</p>

          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default Purchase