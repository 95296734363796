import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AiFeedback.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import Loading from "../Loading/Loading";
import Arrow from "../../assets/images/Arrow.png";
import {
  getCurrentTimeWithMillisecondsISO,
  getCurrentTimeISO,
} from "../../utils/isoDateUtils";
import { useFiles } from "../../contexts/FileContext";
import { useUser } from "../../contexts/UserContext";
import { onSnapshot, doc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { storage, db } from "../../config/firebaseConfig";
import ElectircBlack from "../../assets/images/ElectricBlack.png";
import Tooltip from "../../components/Tooltip/Tooltip";

const AiFeedback = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const { files, createFile } = useFiles();
  const [isLoading, setIsLoading] = useState(false);
  const [inputTxtFile, setInputTxtFile] = useState(null);

  const { checkAndHandlePageVisit } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);
  const reserveButtonRef = useRef(null);

  const tutorialPdf =
    process.env.PUBLIC_URL + "/roleplay_ai_feedback_tutorial.pdf";

  useEffect(() => {
    const initializeTooltip = async () => {
      const isFirstVisitToPage = await checkAndHandlePageVisit(
        "AiFeedbackPage"
      );
      if (isFirstVisitToPage) setShowTooltip(true);
    };
    initializeTooltip();
  }, [checkAndHandlePageVisit]);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setInputTxtFile(droppedFile);
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  const handleTxtFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "text/plain") {
        setInputTxtFile(file);
      } else {
        alert("txt 파일만 업로드할 수 있습니다.");
        setInputTxtFile(null);
        e.target.value = ""; // 잘못된 파일 선택 시 input 초기화
      }
    }
  };

  const handleNextClick = async () => {
    if (userData.userId === null) {
      window.alert("로그인 후 이용할 수 있습니다!");
      return;
    }
    if (isLoading || inputTxtFile === null) return;
    setIsLoading(true);

    // File Data 생성 ()
    const fileId = getCurrentTimeWithMillisecondsISO();
    const fileData = {
      fileId: fileId,
      name: `#${files.length + 1}`,
      practiceDateTime: getCurrentTimeISO(),
      practiceCallNumber: userData.phoneNumber,
      isPartnerOptionRandom: false,
      partnerStyle: "녹음본",
      partnerPurpose: "녹음본",
      userId: userData.userId,
      partnerId: null,
    };

    await createFile(fileData);

    // .txt 업로드
    // Firebase Storage에 .txt 파일 업로드
    const fileRef = ref(storage, `files/${fileId}.txt`);
    await uploadBytes(fileRef, inputTxtFile);

    // File Data 업데이트 감지
    const unsubscribe = onSnapshot(doc(db, "files", fileId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        // 인공지능 피드백 수신 확인
        if (
          data.conversationFeedback &&
          data.summaryFeedback &&
          data.status === "complete"
        ) {
          unsubscribe(); // 구독 해제
          setIsLoading(false);
          // AiFeedbackResult 페이지로 이동
          navigate("/aifeedback-result", { state: { selectedFile: data } });
        }
      }
    });

    return;
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = tutorialPdf;
    link.download = "RolePlay_AI영업피드백_튜토리얼.pdf";
    link.click();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.page}>
      {showTooltip && (
        <Tooltip
          message="클로바 파일을 업로드하면 누를 수 있어요"
          targetRef={reserveButtonRef}
        />
      )}
      <div className={styles.mainContainer}>
        <ResponsiveContainer
          width={880}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <h2 className={styles.title}>
            영업 자료를 업로드하면
            <br />
            AI가 피드백 해드릴게요
          </h2>
          <div className={styles.processContainer}>
            <p className={styles.processTitle}>피드백 프로세스</p>
            <div className={styles.processBlocks}>
              <div className={styles.processBlock}>
                <p className={styles.processBlockText}>
                  영업 파일(.txt)
                  <br />
                  업로드
                </p>
              </div>
              <img className={styles.processArrow} src={Arrow} />
              <div className={styles.processBlock}>
                <p className={styles.processBlockText}>
                  보험 영업 피드백
                  <br />
                  AI 내용 분석
                </p>
              </div>
              <img className={styles.processArrow} src={Arrow} />
              <div className={styles.processBlock}>
                <p className={styles.processBlockText}>피드백 전달</p>
              </div>
            </div>
          </div>
          <div className={styles.textContainer}>
            <p className={styles.p5Text}>영업 자료</p>
            <ResponsiveContainer
              className={styles.fileButton}
              onDrop={handleFileDrop}
              onDragOver={handleFileDragOver}
            >
              <input
                className={styles.uploadInput}
                value={
                  inputTxtFile ? inputTxtFile.name : "txt 파일을드래그해주세요"
                }
                placeholder="txt 파일을드래그해주세요"
                readOnly
              />
            </ResponsiveContainer>

            <ResponsiveContainer>
              <label htmlFor="fileInput">
                <p className={styles.customButtonText3}>pc에서 찾기</p>
              </label>
              <input
                type="file"
                id="fileInput"
                onChange={handleTxtFileChange}
                className={styles.fileInputHidden}
              />
            </ResponsiveContainer>
          </div>
          <div className={styles.blank} />
          <div className={styles.optionGroup} style={{ marginBottom: 0 }}>
            <div className={styles.flexBlank} />
            <div className={styles.buttonContainer}>
              <div onClick={downloadFile} className={styles.tutorialButton}>
                <img src={ElectircBlack} className={styles.electircIcon} />
                <div>빠른 사용법</div>
              </div>
              <button
                ref={reserveButtonRef}
                className={`${styles.nextButton} ${
                  inputTxtFile !== null ? styles.primary : styles.disabled
                }`}
                onClick={handleNextClick}
                disabled={inputTxtFile === null}
              >
                피드백 받기
              </button>
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AiFeedback;
