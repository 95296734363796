export class UserModel {
  constructor({
    userId = null,
    name = null,
    email = null,
    password = null,
    createdDate = null, // ISO 8601: YYYY-MM-DDTHH:MM:SS.sssZ
    isSubscribed = true,
    subscriptionStartDate = null, // ISO 8601: YYYY-MM-DD
    subscriptionEndDate = null, // ISO 8601: YYYY-MM-DD
    role = "customer", // admin, partner, customer
    slackUserId = null,
    teamMembers = [],
    phoneNumber = "",
    visitedPages = [],
  }) {
    this.userId = userId;
    this.name = name;
    this.email = email;
    this.password = password;
    this.createdDate = createdDate;
    this.isSubscribed = isSubscribed;
    this.subscriptionStartDate = subscriptionStartDate;
    this.subscriptionEndDate = subscriptionEndDate;
    this.role = role;
    this.slackUserId = slackUserId;
    this.teamMembers = teamMembers;
    this.phoneNumber = phoneNumber;
    this.visitedPages = visitedPages;
  }
  static fromJson(jsonData) {
    return new UserModel({
      userId: jsonData.userId,
      name: jsonData.name,
      email: jsonData.email,
      password: jsonData.password,
      createdDate: jsonData.createdDate,
      isSubscribed: jsonData.isSubscribed,
      subscriptionStartDate: jsonData.subscriptionStartDate,
      subscriptionEndDate: jsonData.subscriptionEndDate,
      role: jsonData.role,
      slackUserId: jsonData.slackUserId,
      teamMembers: jsonData.teamMembers || [],
      phoneNumber: jsonData.phoneNumber,
      visitedPages: jsonData.visitedPages || [],
    });
  }
  toJson() {
    return {
      userId: this.userId,
      name: this.name,
      email: this.email,
      password: this.password,
      createdDate: this.createdDate,
      isSubscribed: this.isSubscribed,
      subscriptionStartDate: this.subscriptionStartDate,
      subscriptionEndDate: this.subscriptionEndDate,
      role: this.role,
      slackUserId: this.slackUserId,
      teamMembers: this.teamMembers,
      phoneNumber: this.phoneNumber,
      visitedPages: this.visitedPages,
    };
  }
}
