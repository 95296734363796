// import styles from "./PracticeJandi.css";
import React from "react";
import HeatMap from "react-heatmap-grid";
import "./PracticeJandi.css";

const PracticeJandi = ({ files }) => {
  // 날짜 데이터를 생성하는 함수
  const generateHeatmapData = (files) => {
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const yearData = Array.from({ length: 12 }, () =>
      Array.from({ length: 31 }, () => 0)
    );

    files.forEach((file) => {
      if (file.status === "complete") {
        const date = new Date(file.practiceDateTime);
        const month = date.getMonth(); // 0 (January) ~ 11 (December)
        const day = date.getDate() - 1; // 1-based to 0-based index
        yearData[month][day] += 1; // Count
      }
    });

    // Adjust data to match the correct number of days in each month
    return yearData.map((days, month) => days.slice(0, monthDays[month]));
  };

  // 현재 날짜 기준 최근 3개월의 월 레이블 생성
  const getLastThreeMonths = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 현재 월 (0~11)
    const lastThreeMonths = [];

    for (let i = 0; i < 3; i++) {
      const monthIndex = (currentMonth - i + 13) % 12; // 이전 월 계산
      lastThreeMonths.unshift(monthIndex); // 배열 앞쪽에 추가
    }

    return lastThreeMonths;
  };

  const monthLabelsFull = [
    "01월",
    "02월",
    "03월",
    "04월",
    "05월",
    "06월",
    "07월",
    "08월",
    "09월",
    "10월",
    "11월",
    "12월",
  ];

  const dayLabels = Array.from(
    { length: 31 },
    (_, i) => `${String(i + 1).padStart(2, "0")}일`
  );
  const data = generateHeatmapData(files);

  const lastThreeMonths = getLastThreeMonths();

  // 마지막 3개월의 데이터와 레이블만 선택
  const filteredMonthLabels = lastThreeMonths.map(
    (month) => monthLabelsFull[month]
  );
  const filteredData = lastThreeMonths.map((month) => data[month]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "12px",
          paddingTop: "8px",
          paddingBottom: "8px",
          background: "var(--fill-assistive)",
          borderRadius: "8px",
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            width: "32px",
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            paddingLeft: "14px",
          }}
        >
          월
        </div>
        <div
          style={{ fontSize: "14px", fontWeight: "bold", marginLeft: "350px" }}
        >
          연습일
        </div>
      </div>
      <HeatMap
        xLabels={dayLabels} // "연습일" 추가
        yLabels={filteredMonthLabels} // 최근 3개월의 "월" 추가
        data={filteredData} // 최근 3개월 데이터만 전달
        squares
        xLabelsStyle={(index) => ({
          fontSize: "20px",
          color: "#333",
          fontWeight: "normal",
        })}
        yLabelsStyle={(index) => ({
          fontSize: "16px",
          color: "#555",
        })}
        cellStyle={(background, value) => ({
          background: value
            ? `rgba(68, 205, 190, ${Math.min(value / 3, 1)})` // 초록색 농도 조절
            : "var(--fill-normal)", // 기본 배경색
          border: "1px solid #ccc",
          borderRadius: "3px",
          width: "16px", // 셀 가로
          height: "16px", // 셀 세로
          margin: "3px", // 셀 간의 간격
        })}
        cellHeight="22px"
      />
    </div>
  );
};

export default PracticeJandi;
