import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "./../../contexts/UserContext";
import styles from "./Sidebar.module.css";
import NavLogo from "../../assets/images/NavLogo.png";
import Management from "../../assets/images/Management.png";
import Maincalendar from "../../assets/images/Maincalendar.png";
import Partner from "../../assets/images/Partner.png";
import NavCheck from "../../assets/images/NavCheck.png";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import AiFeedbackicon from "../../assets/images/aifeedback_icon.png";

const Sidebar = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const location = useLocation();
  const path = location.pathname;

  const handleNavigation = (selectPath) => {
    navigate(selectPath);
  };

  return (
    <div className={styles.sideSection}>
      <div className={styles.sidebar}>
        <ResponsiveContainer
          className={styles.Signitureimg}
          width={144}
          height={24}
          onClick={() => handleNavigation("/")}
        >
          <img className={styles.NavLogo} src={NavLogo} alt="NavLogo" />
        </ResponsiveContainer>

        <ResponsiveContainer
          className={`${styles.ManagementButton} ${path === "/" || path === "/practice" || path === "/feedback"
            ? styles.activeButton
            : ""
            }`}
          onClick={() => handleNavigation("/")}
        >
          <div className={styles.ManagementContent} width={176} height={42}>
            <img
              src={Management}
              alt="관리 버튼"
              className={styles.ManagementIcon}
            />
            <p className={styles.ManagementText}>관리</p>
          </div>
        </ResponsiveContainer>

        <ResponsiveContainer
          className={`${styles.ReservationButton} ${path === "/reservation" ||
            path === "/reservation-complete" ||
            path === "/timeselection"
            ? styles.activeButton
            : ""
            }`}
          onClick={() => handleNavigation("/reservation")}
        >
          <div className={styles.ReservationContent} width={176} height={42}>
            <img
              src={Maincalendar}
              alt="예약 버튼"
              className={styles.ReservationIcon}
            />
            <p className={styles.ReservationText}>예약</p>
          </div>
        </ResponsiveContainer>

        <ResponsiveContainer
          className={`${styles.ReservationButton} ${path === "/practice-check" ||
            path === "/addpractice" ||
            path === "/addmember" ||
            path === "/viewpractice" ||
            path === "/addpracticecomplete" ||
            path === "/membercomplete"
            ? styles.activeButton
            : ""
            }`}
          onClick={() => handleNavigation("/practice-check")}
        >
          <div className={styles.ReservationContent} width={176} height={42}>
            <img
              src={NavCheck}
              alt="체크 버튼"
              className={styles.ReservationIcon}
            />
            <p className={styles.ReservationText}>체크</p>
          </div>
        </ResponsiveContainer>

        <ResponsiveContainer
          className={`${styles.ReservationButton} ${path === "/aifeedback" || path === "/aifeedback-result"
            ? styles.activeButton
            : ""
            }`}
          onClick={() => handleNavigation("/aifeedback")}
        >
          <div className={styles.ReservationContent} width={176} height={42}>
            <img
              src={AiFeedbackicon}
              alt="AI 영업 피드백 버튼"
              className={styles.ReservationIcon}
            />
            <p className={styles.ReservationText}>AI 영업 피드백</p>
          </div>
        </ResponsiveContainer>

        <nav className={styles.nav}>
          {/* <ResponsiveContainer
                        className={`${styles.CustomerButton} ${path === '/customer' ? styles.activeButton : ''}`}
                        onClick={() => window.open('http://pf.kakao.com/_ChIBxj')}>
                        <div className={styles.CustomerContent} width={176} height={42}>
                            <img src={CustomerService} alt="고객센터 버튼" className={styles.CustomerIcon} />
                            <p className={styles.CustomerText}>고객센터</p>
                        </div>
                    </ResponsiveContainer> */}

          {/* <ResponsiveContainer
            className={`${styles.CustomerButton} ${
              path === "/purchase" ? styles.activeButton : ""
            }`}
            onClick={() => handleNavigation("/purchase")}
          >
            <div className={styles.CustomerContent} width={176} height={42}>
              <img
                src={Purchase}
                alt="구매 버튼"
                className={styles.CustomerIcon}
              />
              <p className={styles.CustomerText}>구매</p>
            </div>
          </ResponsiveContainer> */}

          {userData.role === "partner" && (
            <ResponsiveContainer
              className={`${styles.PartnerButton} ${path === "/partnermain" || path === "/completepractice"
                ? styles.activeButton
                : ""
                }`}
              onClick={() => handleNavigation("/partnermain")}
            >
              <div className={styles.PartnerContent} width={176} height={42}>
                <img
                  src={Partner}
                  alt="파트너 모드"
                  className={styles.PartnerIcon}
                />
                <p className={styles.PartnerText}>파트너모드</p>
              </div>
            </ResponsiveContainer>
          )}

          {!userData.userId ? (
            <ResponsiveContainer
              className={`${styles.CustomerButton} ${path === "/login" ? styles.activeButton : ""
                }`}
              onClick={() => handleNavigation("/login", "login")}
            >
              <div className={styles.loginButton} width={176} height={42}>
                <p className={styles.loginText}>로그인</p>
              </div>
            </ResponsiveContainer>
          ) : null}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
