import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";
import { useFiles } from "../../contexts/FileContext";
import { useUser } from "../../contexts/UserContext";
import FileList from "./../../components/FileList/FileList";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import Electirc from "../../assets/images/Electric.png";
import Tooltip from "../../components/Tooltip/Tooltip";
import ReservationCard from "../../components/ReservationCard/ReservationCard";

const Main = () => {
  const navigate = useNavigate();
  const { isInitialized, files } = useFiles();
  const { checkAndHandlePageVisit } = useUser();
  const [showTooltip, setShowTooltip] = useState(false);
  const reserveButtonRef = useRef(null);
  const tutorialPdf = process.env.PUBLIC_URL + "/roleplay_tutorial.pdf";
  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const initializeTooltip = async () => {
      const isFirstVisitToPage = await checkAndHandlePageVisit("MainPage");
      if (isFirstVisitToPage) setShowTooltip(true);
    };
    initializeTooltip();
  }, [checkAndHandlePageVisit]);

  const handleFileClick = (file) => {
    if (file.status !== "complete") {
      navigate("/practice", { state: { selectedFile: file } });
    } else {
      navigate("/feedback", { state: { selectedFile: file } });
    }
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = tutorialPdf;
    link.download = "RolePlay_Tutorial.pdf";
    link.click();
  };

  useEffect(() => {
    if (isInitialized && files.length < 1) {
      const checkTodayInLocalStorage = () => {
        const today = new Date().toISOString().split("T")[0]; // 오늘 날짜 (YYYY-MM-DD)
        const storedDate = localStorage.getItem("storedDate");

        if (storedDate !== today) {
          localStorage.setItem("storedDate", today);
          navigate("/onboarding");
        }
      };

      checkTodayInLocalStorage();
    }
  }, [isInitialized])

  return (
    <div className={styles.page}>
      {showTooltip && (
        <Tooltip message="버튼을 클릭해보세요" targetRef={reserveButtonRef} />
      )}
      <div className={styles.mainContainer1}>
        <ResponsiveContainer width={880}>
          <div className={styles.header1}>
            <p className={styles.practiceListText}>
              연습하고 싶은 상대를 선택하세요
            </p>
            <ReservationCard />
          </div>
        </ResponsiveContainer>
      </div>
      <div className={styles.mainContainer2}>
        <ResponsiveContainer width={880}>
          <div className={styles.header2}>
            <p className={styles.practiceListText}>상담 목록</p>
            {/* {showTooltip && <Tooltip message={"버튼을 클릭해보세요!"} />} */}
            <button
              ref={reserveButtonRef}
              onClick={() => handleNavigation("/reservation")}
              className={styles.reserveButton}
            >
              연습 예약하기
            </button>
          </div>
          <FileList files={files} onFileClick={handleFileClick} />
        </ResponsiveContainer>
      </div>
      <div onClick={downloadFile} className={styles.tutorialButton}>
        <img src={Electirc} className={styles.electircIcon} />
        <div>빠른 사용법</div>
      </div>
    </div>
  );
};

export default Main;
