// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCaXnPSHVLipdIVOOuq0EsliNRa2La3_UQ",
  authDomain: "intalk-2326a.firebaseapp.com",
  projectId: "intalk-2326a",
  storageBucket: "intalk-2326a.firebasestorage.app",
  messagingSenderId: "1014867181392",
  appId: "1:1014867181392:web:b685fb829afe0c13bb3655",
};

// Firebase 앱 초기화
const app = initializeApp(firebaseConfig);

// Firestore 및 Auth 인스턴스 초기화
const db = getFirestore(app);
const auth = getAuth(app);

export const storage = getStorage(app);

export { db, auth };
