import React from "react";
import "./WeekCalendar.css";
import { useNavigate, useLocation } from "react-router-dom";
import CheckImage from "../../assets/images/complete/Check.png";
import { format, startOfWeek, addDays } from "date-fns"; // date-fns 사용
import { ko } from "date-fns/locale"; // 한국어 로케일 추가
import ReactGA from "react-ga4"; 

const WeekCalendar = ({
  files,
  practices,
  onIndicatorClick,
  onPracticeClick,
}) => {
  const currentDate = new Date(); // 현재 날짜
  const weekStartDate = startOfWeek(currentDate, { weekStartsOn: 1 }); // 월요일 시작
  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    addDays(weekStartDate, i)
  );
  const navigate = useNavigate();
  // 요일과 날짜를 각각 반환하는 함수
  const getDayLabel = (date) => format(date, "eee", { locale: ko }); // 한국어 요일
  const getDateLabel = (date) => format(date, "MM월 dd일"); // 날짜 (11월 25일)

  const isComplete = (date) => {
    const targetDate = format(date, "yyyy-MM-dd");
    return files.filter(
      (file) =>
        file.status === "complete" &&
        file.practiceDateTime.startsWith(targetDate)
    );
  };

  const getPracticesForDate = (date) => {
  const targetDate = format(date, "yyyy-MM-dd"); // 기준 날짜 (로컬 시간)
  return practices.filter((practice) => {
    // UTC 시간 기준으로 저장된 시간을 한국 시간으로 변환
    const practiceDateUTC = new Date(practice.addPracticeDate);
    const practiceDateKST = new Date(practiceDateUTC.getTime() + 9 * 60 * 60 * 1000); // 9시간 추가
    const formattedPracticeDate = format(practiceDateKST, "yyyy-MM-dd");
    return formattedPracticeDate === targetDate; // 변환된 날짜와 비교
  });
};

  const getWeekRange = () => {
    const start = format(daysOfWeek[0], "MM월 dd일");
    const end = format(daysOfWeek[6], "MM월 dd일");
    return `${start} ~ ${end}`;
  };

  const goToAddPractice = () => {
    // GA 이벤트 추가
    ReactGA.event({
      category: "User",
      action: "Clicked Add Practice Button",
      label: "Add Practice",
    });

    navigate("/AddPractice");
  };
  return (
    <div className="week-calendar">
      <div className="week-header">
        <div className="week-title">이번주 ({getWeekRange()})</div>
        <div className="addPracticeBtn" onClick={goToAddPractice}>
          연습 직접 추가하기
        </div>
      </div>
      <div className="calendar-container">
        <div className="calendar-header">
          {daysOfWeek.map((date, index) => (
            <div key={index} className="calendar-cell">
              <div className="day-label">{getDayLabel(date)}</div> {/* 요일 */}
              <div className="date-label">{getDateLabel(date)}</div>{" "}
              {/* 날짜 */}
            </div>
          ))}
        </div>
        <div className="calendar-body">
          {daysOfWeek.map((date, index) => {
            const filesForDate = isComplete(date);
            const practicesForDate = getPracticesForDate(date);
            // 추가된 로직: files 또는 practices 중 하나라도 있으면 표시
            const hasDataForDate =
              filesForDate.length > 0 || practicesForDate.length > 0;

            return (
              <div key={index} className="calendar-cell">
                {hasDataForDate && (
                  <div>
                    <img
                      src={CheckImage}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                )}
                {filesForDate.map((file) => (
                  <div
                    key={file.fileId}
                    onClick={() => onIndicatorClick(file)}
                    className="file-indicator"
                  >
                    Role-Play 연습
                  </div>
                ))}
                {practicesForDate.map((practice) => (
                  <div
                    key={practice.practiceId}
                    onClick={() => onPracticeClick(practice)}
                    className="practice-indicator"
                  >
                    {practice.title}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WeekCalendar;
