import { UserModel } from '../../models/UserModel';

const userDummyData = new UserModel({
  userId: 'user1',
  email: 'user1@example.com',
  password: 'password123',
  createdDate: '2024-01-01T10:00:00.000Z',
  isSubscribed: true,
  subscriptionStartDate: '2024-01-01',
  subscriptionEndDate: '2025-01-01',
  role: 'partner',
  slackUserId: 'U12345'
});

export default userDummyData;