export class FileModel {
    constructor({
        fileId,
        name,
        practiceDateTime, // ISO 8601: YYYY-MM-DDTHH:MM:SSZ
        practiceCallNumber, // 010-XXXX-XXXX
        status = 'notStarted', // notStarted, inProgress, complete
        isPartnerOptionRandom,
        partnerStyle, // 긍정적, 부정적
        partnerPurpose, // 보험 가입 의향 고객, 단순 정보 탐색 고객, 비교 분석 고객, DB 마케팅 유입 고객, 자기 보험금 대리 청구 고객
        conversationFeedback = null,
        summaryFeedback = null,
        practiceRecordingLink = null,
        userId,
        partnerId
    }) {
        this.fileId = fileId;
        this.name = name;
        this.practiceDateTime = practiceDateTime;
        this.practiceCallNumber = practiceCallNumber;
        this.status = status;
        this.isPartnerOptionRandom = isPartnerOptionRandom;
        this.partnerStyle = partnerStyle;
        this.partnerPurpose = partnerPurpose;
        this.conversationFeedback = conversationFeedback;
        this.summaryFeedback = summaryFeedback;
        this.practiceRecordingLink = practiceRecordingLink;
        this.userId = userId;
        this.partnerId = partnerId;
    }

    static fromJson(jsonData) {
        return new FileModel({
            fileId: jsonData.fileId,
            name: jsonData.name,
            practiceDateTime: jsonData.practiceDateTime,
            practiceCallNumber: jsonData.practiceCallNumber,
            status: jsonData.status,
            isPartnerOptionRandom: jsonData.isPartnerOptionRandom,
            partnerStyle: jsonData.partnerStyle,
            partnerPurpose: jsonData.partnerPurpose,
            conversationFeedback: jsonData.conversationFeedback,
            summaryFeedback: jsonData.summaryFeedback,
            practiceRecordingLink: jsonData.practiceRecordingLink,
            userId: jsonData.userId,
            partnerId: jsonData.partnerId
        });
    }

    toJson() {
        return {
            fileId: this.fileId,
            name: this.name,
            practiceDateTime: this.practiceDateTime,
            practiceCallNumber: this.practiceCallNumber,
            status: this.status,
            isPartnerOptionRandom: this.isPartnerOptionRandom,
            partnerStyle: this.partnerStyle,
            partnerPurpose: this.partnerPurpose,
            conversationFeedback: this.conversationFeedback,
            summaryFeedback: this.summaryFeedback,
            practiceRecordingLink: this.practiceRecordingLink,
            userId: this.userId,
            partnerId: this.partnerId
        };
    }
}