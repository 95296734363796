import axios from "axios";

const SLACK_FUNCTION_URL = "https://sendmessage-sazqb25tmq-uc.a.run.app";

/**
 * slackSendMessage
 * @param {string} message - Slack에 전송할 메시지 내용
 * @returns {Promise<void>}
 */
const slackSendMessage = async (message) => {
  try {
    const response = await axios.post(SLACK_FUNCTION_URL, { message });
    if (response.status !== 200) {
      console.warn("Failed to send message:", response.data);
    }
  } catch (error) {
    console.error("Error sending message to Slack:", error);
    throw new Error("Failed to send message");
  }
};

export default slackSendMessage;