import React, { useEffect, useState } from 'react'
import styles from './Block.module.css';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import NoButton from '../../assets/images/No.png';


function Block() {

    return (
    <div className={styles.container}>
    <div className={styles.customContainer}>
        <ResponsiveContainer  className={styles.CheckButtonContainer}  width={40}>
          <img className={styles.CheckButtonImg} src={NoButton} alt="checkbutton Icon" />
        </ResponsiveContainer>
      <p className={styles.p1Text}>모바일로</p>
      <p className={styles.p1Text}>접속할 수 없습니다.</p>
      <p className={styles.p2Text}>PC에서만 이용할 수 있어요</p>
      <ResponsiveContainer height={35} width={300} className={styles.customButton}>
        <p className={styles.customButtonText}>확인</p>
      </ResponsiveContainer>
    </div>
  </div>
  )
}

export default Block