// 시간까지 가져오는 함수 (YYYY-MM-DDTHH:MM:SS)
export function getCurrentTimeISO() {
    const kstTime = new Date(Date.now() + 9 * 60 * 60 * 1000); // 9시간 추가
    return kstTime.toISOString().slice(0, 19); // "YYYY-MM-DDTHH:MM:SS"
}

// 밀리초까지 가져오는 함수 (YYYY-MM-DDTHH:MM:SS.sss)
export function getCurrentTimeWithMillisecondsISO() {
    const kstTime = new Date(Date.now() + 9 * 60 * 60 * 1000); // 9시간 추가
    return kstTime.toISOString().slice(0, 23); // "YYYY-MM-DDTHH:MM:SS.sss"
}

// 날짜까지만 가져오는 함수 (YYYY-MM-DD)
export function getCurrentDateISO() {
    const kstTime = new Date(Date.now() + 9 * 60 * 60 * 1000); // 9시간 추가
    return kstTime.toISOString().slice(0, 10); // "YYYY-MM-DD"
}