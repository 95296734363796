// Loading.js
import React from 'react';
import Lottie from "lottie-react";
import styles from './Loading.module.css';
import LoadingAnimation  from '../../assets/data/Loading.json'
const Loading = () => {
    
    return (
        <div className={styles.container}>
            <div className={styles.customContainer}>
                <span className={styles.loadingText}>로딩 중...</span>
                <Lottie animationData={LoadingAnimation} />
            </div>
        </div>
    );
};

export default Loading;