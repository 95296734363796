import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import Loading from "../Loading/Loading";
import sha256 from "crypto-js/sha256";
import ReactGA from "react-ga4";

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_REST_API_KEY;
const REDIRECT_URI = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}/signup`;
const SALT = "fixed_salt_value";

function SignUp() {
  const navigate = useNavigate();
  const location = useLocation(); // React Router의 useLocation 훅 사용

  const { userData, registerUser, loginUser, fetchUserByEmail } = useUser();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");

    if (code) {
      fetchAccessToken(code);
    }
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   // 로컬 환경: navigate로 전달된 더미 이메일 처리
  //   const query = new URLSearchParams(window.location.search);
  //   const code = query.get("code");

  //   if (process.env.NODE_ENV === "development") {
  //     console.log("Development environment: Using dummy email.");
  //     const dummyEmail = location.state?.email || "lim9660@kakao.com"; // 전달된 이메일 또는 기본값
  //     handleUserRegistrationOrLogin(dummyEmail);
  //     return;
  //   }

  //   // 배포 환경: 카카오 로그인 코드 처리
  //   if (code) {
  //     fetchAccessToken(code);
  //   }
  // }, []);

  const fetchAccessToken = async (code) => {
    const tokenUrl = "https://kauth.kakao.com/oauth/token";
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", KAKAO_CLIENT_ID);
    params.append("redirect_uri", REDIRECT_URI);
    params.append("code", code);

    const response = await fetch(tokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params.toString(),
    });

    if (response.ok) {
      const data = await response.json();
      const accessToken = data.access_token;
      fetchKakaoUserProfile(accessToken);
    } else {
      console.error("Error fetching access token:", response.statusText);
    }
  };

  const fetchKakaoUserProfile = async (accessToken) => {
    const userUrl = "https://kapi.kakao.com/v2/user/me";

    const response = await fetch(userUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const userData = await response.json();
      const kakaoEmail = userData.kakao_account.email;
      handleUserRegistrationOrLogin(kakaoEmail);
    } else {
      console.error("Error fetching user profile:", response.statusText);
    }
  };

  const handleUserRegistrationOrLogin = async (email) => {
    const hashedPassword = sha256(email + SALT).toString();
    try {
      const isLoginSuccess = await loginUser(email);

      if (isLoginSuccess === false) {
        // 사용자 정보가 없는 경우 회원가입
        await registerUser(email, hashedPassword);

        // **회원가입 성공 시 GA 이벤트 추가**
        ReactGA.event({
          category: "User",
          action: "SignUp Success",
          label: "SignUp Page",
        });
        console.log("GA Event Sent: SignUp Success");

        navigate("/phonenumber", { state: { email } });
      } else if (isLoginSuccess === true) {
        // **로그인 성공 시 GA 이벤트 추가**
        ReactGA.event({
          category: "User",
          action: "Login Success",
          label: "SignUp Page",
        });
        console.log("GA Event Sent: Login Success");
        // Firestore에서 사용자 정보 가져오기
        const user = await fetchUserByEmail(email);

        if (user && !user.phoneNumber) {
          console.log("전화번호 없음, PhoneNumber 페이지로 이동");
          navigate("/phonenumber", { state: { email } });
        } else {
          console.log("로그인 성공");
          navigate("/", { state: { userId: userData.userId } });
        }
      } else {
        alert("문제가 발생하였습니다. 다시 시도해주세요.");
        navigate("/", { state: { userId: userData.userId } });
      }
    } catch (error) {
      console.error("회원 가입 또는 로그인 오류:", error);
    }
  };
  return <Loading />;
}

export default SignUp;
