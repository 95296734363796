import { FileModel } from './../../models/FileModel';

const fileDummyData = [
  new FileModel({
    fileId: 'file1',
    name: '#1',
    practiceDateTime: '2024-09-01T11:00:00Z',
    practiceCallNumber: '010-1234-5678',
    status: 'completed',
    isPartnerOptionRandom: false,
    partnerStyle: '긍정적',
    partnerPurpose: 'DB 마케팅 유입 고객',
    conversationFeedback: 'Good engagement with the client.',
    summaryFeedback: 'Client interested in DB marketing.',
    practiceRecordingLink: 'https://example.com/recording1',
    userId: 'user1',
    partnerId: 'partner1'
  }),
  new FileModel({
    fileId: 'file2',
    name: '#2',
    practiceDateTime: '2024-10-01T11:00:00Z',
    practiceCallNumber: '010-2345-6789',
    status: 'completed',
    isPartnerOptionRandom: true,
    partnerStyle: '랜덤',
    partnerPurpose: '랜덤',
    conversationFeedback: 'Interaction was satisfactory.',
    summaryFeedback: 'Client was somewhat interested.',
    practiceRecordingLink: 'https://example.com/recording2',
    userId: 'user1',
    partnerId: 'partner2'
  }),
  new FileModel({
    fileId: 'file3',
    name: '#3',
    practiceDateTime: '2024-11-01T11:00:00Z',
    practiceCallNumber: '010-3456-7890',
    status: 'inProgress',
    isPartnerOptionRandom: false,
    partnerStyle: '부정적',
    partnerPurpose: '단순 정보 탐색 고객',
    conversationFeedback: 'Client had a lot of questions.',
    summaryFeedback: 'Client was only browsing for information.',
    practiceRecordingLink: 'https://example.com/recording3',
    userId: 'user1',
    partnerId: 'partner3'
  }),
  new FileModel({
    fileId: 'file4',
    name: '#4',
    practiceDateTime: '2024-12-01T11:00:00Z',
    practiceCallNumber: '010-4567-8901',
    status: 'notStarted',
    isPartnerOptionRandom: false,
    partnerStyle: '긍정적',
    partnerPurpose: '보험 가입 의향 고객',
    conversationFeedback: '',
    summaryFeedback: '',
    practiceRecordingLink: '',
    userId: 'user1',
    partnerId: 'partner4'
  }),
  new FileModel({
    fileId: 'file5',
    name: '#5',
    practiceDateTime: '2025-01-01T11:00:00Z',
    practiceCallNumber: '010-5678-9012',
    status: 'notStarted',
    isPartnerOptionRandom: true,
    partnerStyle: '랜덤',
    partnerPurpose: '자기 보험금 대리 청구 고객',
    conversationFeedback: '',
    summaryFeedback: '',
    practiceRecordingLink: '',
    userId: 'user1',
    partnerId: 'partner5'
  })
];

export default fileDummyData;
