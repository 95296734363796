import React from "react";
import styles from "./ReservationCard.module.css";
import { useNavigate } from "react-router-dom";
import ReservationIcon1 from "../../assets/images/reservation/ReservationIcon1.png";
import ReservationIcon2 from "../../assets/images/reservation/ReservationIcon2.png";
import ReservationIcon3 from "../../assets/images/reservation/ReservationIcon3.png";
import ReservationIcon4 from "../../assets/images/reservation/ReservationIcon4.png";
import ReservationIcon5 from "../../assets/images/reservation/ReservationIcon5.png";
import ReservationIcon6 from "../../assets/images/reservation/ReservationIcon6.png";
const options = [
  {
    id: 1,
    label: "빠른 예약하기",
    isPartnerOptionRandom: true,
    partnerStyle: "랜덤",
    partnerPurpose: "랜덤",
    img: ReservationIcon1,
  },
  {
    id: 2,
    label: "보험 가입 의향 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "긍정적",
    partnerPurpose: "보험 가입 의향 고객",
    img: ReservationIcon2,
  },
  {
    id: 3,
    label: "단순 정보 탐색 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "긍정적",
    partnerPurpose: "단순 정보 탐색 고객",
    img: ReservationIcon3,
  },
  {
    id: 4,
    label: "비교 분석 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "긍정적",
    partnerPurpose: "비교 분석 고객",
    img: ReservationIcon4,
  },
  {
    id: 5,
    label: "DB 마케팅 유입 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "긍정적",
    partnerPurpose: "DB 마케팅 유입 고객",
    img: ReservationIcon5,
  },
  {
    id: 6,
    label: "보험금 대리 청구 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "긍정적",
    partnerPurpose: "자기 보험금 대리 청구 고객",
    img: ReservationIcon6,
  },
  {
    id: 7,
    label: "보험 가입 의향 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "부정적",
    partnerPurpose: "보험 가입 의향 고객",
    img: ReservationIcon2,
  },
  {
    id: 8,
    label: "단순 정보 탐색 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "부정적",
    partnerPurpose: "단순 정보 탐색 고객",
    img: ReservationIcon3,
  },
  {
    id: 9,
    label: "비교 분석 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "부정적",
    partnerPurpose: "비교 분석 고객",
    img: ReservationIcon4,
  },
  {
    id: 10,
    label: "DB 마케팅 유입 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "부정적",
    partnerPurpose: "DB 마케팅 유입 고객",
    img: ReservationIcon5,
  },
  {
    id: 11,
    label: "보험금 대리 청구 고객",
    isPartnerOptionRandom: false,
    partnerStyle: "부정적",
    partnerPurpose: "자기 보험금 대리 청구 고객",
    img: ReservationIcon6,
  },
];

const ReservationCard = () => {
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    navigate("/reservation", { state: option }); // 옵션 객체 그대로 전달
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.optionsGrid}>
        {options.map((option) => (
          <div
            key={option.id}
            className={styles.optionCard}
            onClick={() => handleOptionClick(option)}
          >
            <img src={option.img} className={styles.optionIcon} />
            {option.label}

            <span
              className={`${styles.partnerStyle} ${
                styles[
                  option.partnerStyle === "긍정적"
                    ? "positive"
                    : option.partnerStyle === "부정적"
                    ? "negative"
                    : "random"
                ]
              }`}
            >
              {option.partnerStyle}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReservationCard;
