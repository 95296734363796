import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import Loading from "../Loading/Loading"; // Loading 컴포넌트
import { useUser } from "../../contexts/UserContext"; // 유저 정보 가져오기
import { usePractices } from "../../contexts/PracticeContext"; // 연습 데이터 관리
import styles from "./AddPractice.module.css";
import { getCurrentTimeWithMillisecondsISO } from "../../utils/isoDateUtils";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import ReactGA from "react-ga4"; // GA 라이브러리 추가

function AddPractice() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { userData } = useUser(); // UserContext에서 사용자 정보 가져오기
  const { createPractice } = usePractices(); // PracticeContext에서 createPractice 메서드 가져오기
  const navigate = useNavigate();

  const handleAddPractice = async () => {
    // 사용자 로그인 확인
    if (!userData || !userData.userId) {
      setError("로그인된 사용자가 없습니다.");
      return;
    }

    // 필수 필드 입력 확인
    if (!selectedDate || !title || !content) {
      setError("모든 칸을 입력해주세요.");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      // KST 시간 생성
      const kstDate = new Date(selectedDate);
      const koreaPracticeDateTime = new Date(kstDate.getTime() + 9 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 19); // YYYY-MM-DDTHH:MM:SS 형식

      // PracticeContext의 createPractice 메서드를 호출하여 데이터 저장
      await createPractice({
        practiceId: getCurrentTimeWithMillisecondsISO(), // 고유 ID 생성
        addPracticeDate: koreaPracticeDateTime, // KST 시간 저장
        title,
        content,
        userId: userData.userId, // 로그인한 사용자 ID
      });

      // **연습 데이터 추가 성공 시 GA 이벤트 전송**
      ReactGA.event({
        category: "Practice",
        action: "Add Practice Success",
        label: "AddPractice Page",
      });
      console.log("GA Event Sent: Add Practice Success");

      console.log("연습 항목 추가 완료");
      setSelectedDate(null);
      setTitle("");
      setContent("");

      // 데이터 저장 완료 후 이동
      navigate("/addpracticecomplete");
    } catch (err) {
      console.error("연습 데이터 추가 오류:", err);
      setError("데이터를 저장하는 중 문제가 발생했습니다.");

      // **연습 데이터 추가 실패 시 GA 이벤트 전송**
      ReactGA.event({
        category: "Error",
        action: "Add Practice Failed",
        label: "AddPractice Page",
      });
      console.log("GA Event Sent: Add Practice Failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.page}>
          <div className={styles.mainContainer}>
            <ResponsiveContainer width={880}>
              <p className={styles.TitleText}>추가할 연습을</p>
              <p className={styles.TitleText2}>적어주세요</p>
              <div className={styles.inputContainer}>
                <p className={styles.p2Text}>날짜</p>
                <div className={styles.flexBlank} />
                <CustomDatePicker
                  selectedDate={selectedDate}
                  onChange={setSelectedDate}
                />
              </div>

              <div className={styles.inputContainer2}>
                <p className={styles.p2Text}>제목</p>
                <input
                  type="text"
                  placeholder="제목을 입력하세요"
                  className={styles.Input}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className={styles.inputContainer2}>
                <p className={styles.p2Text}>내용</p>
                <input
                  type="text"
                  placeholder="연습한 내용을 간단히 적어주세요"
                  className={styles.Input}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </div>

              {error && <p className={styles.errorText}>{error}</p>}

              <button
                className={`${styles.addButton} ${
                  selectedDate && title && content ? styles.enabled : ""
                }`}
                onClick={handleAddPractice}
                disabled={!selectedDate || !title || !content}
              >
                추가하기
              </button>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddPractice;
