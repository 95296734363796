import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Admin.module.css';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { useUser } from '../../contexts/UserContext';
import Loading from '../Loading/Loading';

const Admin = () => {
    const [formData, setFormData] = useState({ uid: '', slackUserId: '' });
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { updatePartnerUser } = useUser();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        checkValid({ ...formData, [name]: value });
    };

    const checkValid = ({ uid, slackUserId }) => {
        setIsValid(uid.trim() !== '' && slackUserId.trim() !== '');
    };

    const handleNextClick = async () => {
        setIsLoading(true);
        const updatedData = { userId: formData.uid, slackUserId: formData.slackUserId, role: 'partner' };
        await updatePartnerUser(updatedData);
        setIsLoading(false);
        navigate("/admin-complete");
    };

    return isLoading ? (<Loading />) : (
        <div className={styles.page}>
            <div className={styles.mainContainer}>
                <ResponsiveContainer width={880} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h2 className={styles.title}>파트너 등록</h2>
                    <div className={styles.optionGroup}>
                        <label className={styles.optionTitle}>파트너 UID</label>
                        <div className={styles.flexBlank} />
                        <input
                            className={styles.customInput}
                            name="uid"
                            placeholder="firebase에서 확인하세요"
                            value={formData.uid}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className={styles.optionGroup}>
                        <label className={styles.optionTitle}>슬랙 파트너 User ID</label>
                        <div className={styles.flexBlank} />
                        <input
                            className={styles.customInput}
                            name="slackUserId"
                            placeholder="Slack에서 확인하세요"
                            value={formData.slackUserId}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className={styles.optionGroup} style={{ marginBottom: 0 }}>
                        <div className={styles.flexBlank} />
                        <button
                            className={`${styles.nextButton} ${isValid ? styles.primary : styles.disabled}`}
                            onClick={handleNextClick}
                            disabled={!isValid}
                        >
                            등록하기
                        </button>
                    </div>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Admin;