import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Onboarding.module.css";
import View_1 from "../../assets/images/onboarding/View_1.png";
import View_2 from "../../assets/images/onboarding/View_2.png";
import View_3 from "../../assets/images/onboarding/View_3.png";
import View_4 from "../../assets/images/onboarding/View_4.png";
import LeftArrow from "../../assets/images/onboarding/LeftArrow.png";
import RightArrow from "../../assets/images/onboarding/RightArrow.png";
import Signature from "../../assets/images/onboarding/Signiture.png";

const Onboarding = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const content = [
        ["Role-Play", "돈을 안들이고 TA 연습을 진행할 수 있어요", View_1],
        ["연습 예약", "원하는 유형의 파트너와 연습을 예약할 수 있어요", View_2],
        ["피드백 제공", "연습 내역을 녹음하여 개선방안과 잘한점, 적용할 점을 제공해드려요", View_3],
        ["연습 체크", "소속 설계사들이 연습을 얼마나 했는지 체크할 수 있어요", View_4]
    ]
    const navigation = useNavigate();

    const handlePrev = () => {
        if (currentPage > 1) setCurrentPage((prev) => prev - 1);
    };

    const handleNext = () => {
        if (currentPage < 4) setCurrentPage((prev) => prev + 1);
    };

    const handleNextPage = () => {
        navigation('/main');
    }

    return (
        <div className={styles.onboarding}>
            <div className={styles.mainContainer}>
                <button className={`${styles.paginationButton} ${styles.leftButton}`} onClick={handlePrev} disabled={currentPage === 1}>
                    <img className={styles.buttonIcon} src={LeftArrow} />
                </button>
                <div className={styles.container}>
                    {/* 1. 맨 위 요소 */}
                    <div className={styles.header}>
                        {currentPage === 1 ? <img className={styles.titleIcon} src={Signature} /> : (<h1>{content[currentPage - 1][0]}</h1>)}
                        <p>{content[currentPage - 1][1]}</p>
                    </div>

                    {/* 3. 세 번째 요소 */}
                    <div className={styles.imageContainer}>
                        <img src={content[currentPage - 1][2]} alt="Sample Table" />
                    </div>

                    {/* 6. 시작하기 버튼 (4번째 페이지에서만 표시) */}
                    {currentPage === 4 && (
                        <div className={styles.startButton}>
                            <button onClick={handleNextPage}>시작하기</button>
                        </div>
                    )}
                </div>
                <button className={`${styles.paginationButton} ${styles.rightButton}`} onClick={handleNext} disabled={currentPage === 4}>
                    <img className={styles.buttonIcon} src={RightArrow} />
                </button>
            </div>
        </div>
    );
}

export default Onboarding;