import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Main from "./pages/Main/Main";
import FeedBack from "./pages/Feedback/FeedBack";
import Login from "./pages/Login/Login";
import Practice from "./pages/Practice/Practice";
import PracticeCheck from "./pages/PracticeCheck/PracticeCheck";
import Purchase from "./pages/Purchase/Purchase";
import Reservation from "./pages/Reservation/Reservation";
import ReservationComplete from "./pages/ReservationComplete/ReservationComplete";
import SignUp from "./pages/SignUp/SignUp";
import PhoneNumber from "./pages/PhoneNumber/PhoneNumber";
import TimeSelection from "./pages/TimeSelection/TimeSelection";
import LoginComplete from "./pages/LoginComplete/LoginComplete";
import CompletePractice from "./pages/CompletePractice/CompletePractice";
import PartnerMain from "./pages/PartnerMain/PartnerMain";
import Admin from "./pages/Admin/Admin";
import AdminComplete from "./pages/AdminComplete/AdminComplete";
import PartnerComplete from "./pages/PartnerComplete/PartnerComplete";
import AddPractice from "./pages/AddPractice/AddPractice";
import ViewPractice from "./pages/ViewPractice/ViewPractice";
import MemberComplete from "./pages/MemberComplete/MemberComplete";
import AddMember from "./pages/AddMember/AddMember";
import AddPracticeComplete from "./pages/AddPracticeComplete/AddPracticeComplete";
import Block from "./pages/Block/Block";
import Sidebar from "./components/Sidebar/Sidebar";
import { UserProvider } from "./contexts/UserContext";
import { FileProvider } from "./contexts/FileContext";
import { PracticeProvider } from "./contexts/PracticeContext";
import styles from "./App.module.css";
import ReactGA from "react-ga4";
import Analytics from "./components/Analytics";
import AiFeedback from "./pages/AiFeedback/AiFeedback";
import AiFeedbackResult from "./pages/AiFeedbackResult/AiFeedbackResult";
import Onboarding from "./pages/Onboarding/Onboarding";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  // 화면 크기를 체크하는 함수
  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 768); // 모바일 크기인 경우 true로 설정
  };

  useEffect(() => {
    ReactGA.initialize("G-REYFMLDN6F"); // Google Analytics 초기화
    ReactGA.send("pageview"); // 첫 페이지 로드 시 페이지뷰 기록
    checkIfMobile(); // 초기 렌더링 시 체크
    window.addEventListener("resize", checkIfMobile); // 화면 크기 변경 시 체크
    return () => {
      window.removeEventListener("resize", checkIfMobile); // 컴포넌트 언마운트 시 이벤트 리스너 정리
    };
  }, []);

  if (isMobile) {
    return <Block />; // 모바일에서는 Block 컴포넌트만 렌더링
  }

  return (
    <UserProvider>
      <FileProvider>
        <PracticeProvider>
          <Router>
            <Analytics />
            <div className={styles.container}>
              <ConditionalSidebar />
              <div className={styles.content}>
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/feedback" element={<FeedBack />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logincomplete" element={<LoginComplete />} />
                  <Route path="/practice" element={<Practice />} />
                  <Route path="/purchase" element={<Purchase />} />
                  <Route path="/reservation" element={<Reservation />} />
                  <Route path="/practice-check" element={<PracticeCheck />} />
                  <Route
                    path="/partnercomplete"
                    element={<PartnerComplete />}
                  />
                  <Route
                    path="/reservation-complete"
                    element={<ReservationComplete />}
                  />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/phonenumber" element={<PhoneNumber />} />
                  <Route path="/block" element={<Block />} />
                  <Route
                    path="/completepractice"
                    element={<CompletePractice />}
                  />
                  <Route path="/timeselection" element={<TimeSelection />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/admin-complete" element={<AdminComplete />} />
                  <Route path="/membercomplete" element={<MemberComplete />} />
                  <Route path="/addpractice" element={<AddPractice />} />
                  <Route path="/addmember" element={<AddMember />} />
                  <Route
                    path="/addpracticecomplete"
                    element={<AddPracticeComplete />}
                  />
                  <Route path="/viewpractice" element={<ViewPractice />} />
                  <Route path="/partnermain" element={<PartnerMain />} />
                  <Route path="/aifeedback" element={<AiFeedback />} />
                  <Route path="/aifeedback-result" element={<AiFeedbackResult />}/>
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="*" element={<Navigate to="/" />} />{" "}
                  {/* 잘못된 경로일 경우 메인 페이지로 리디렉션 */}
                </Routes>
              </div>
            </div>
          </Router>
        </PracticeProvider>
      </FileProvider>
    </UserProvider>
  );
}

// 조건부 Sidebar 렌더링 컴포넌트
function ConditionalSidebar() {
  const location = useLocation();

  // 로그인 및 회원가입 페이지에서는 Sidebar를 숨김
  const pathsWithoutSidebar = ["/login", "/signup", "/phonenumber", "/onboarding"];
  if (pathsWithoutSidebar.includes(location.pathname)) {
    return null;
  }

  return <Sidebar />;
}

export default App;
