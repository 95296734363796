import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../CompletePractice/CompletePractice.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import { useFiles } from "../../contexts/FileContext";
import FileList from "../../components/FileList/FileList";
import Loading from "../Loading/Loading";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/firebaseConfig";

function CompletePractice() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedFile } = state || {}; 
  const { partnerFiles, updateFile } = useFiles();
  const [fileData, setFileData] = useState(null);
  const [inputUrl, setInputUrl] = useState("");
  const [inputTxtFile, setInputTxtFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const [clovaRecordingClicked, setClovaRecordingClicked] = useState(false);
  const [practiceStartedClicked, setPracticeStartedClicked] = useState(false);
  const [practiceFinishedClicked, setPracticeFinishedClicked] = useState(false);

  useEffect(() => {
    if (selectedFile?.fileId) {
      setFileData(selectedFile); // selectedFile을 바로 사용
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [selectedFile]);

  const handleTxtFileChange = (e) => setInputTxtFile(e.target.files[0]);
  const handleUrlChange = (e) => setInputUrl(e.target.value);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setInputTxtFile(droppedFile);
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (inputTxtFile && inputUrl && clovaRecordingClicked && practiceStartedClicked) {
      setPracticeFinishedClicked(true);
    } else {
      setPracticeFinishedClicked(false);
    }
  }, [inputTxtFile, inputUrl, clovaRecordingClicked, practiceStartedClicked]);

  const handleFinishPractice = async () => {
    if (inputTxtFile && inputUrl && selectedFile?.fileId) {
      try {
        setLoading(true);

        // Firebase Storage에 .txt 파일 업로드
        const fileRef = ref(storage, `files/${selectedFile.fileId}.txt`);
        await uploadBytes(fileRef, inputTxtFile);

        // updateFile을 사용해 Firestore에 URL과 상태 업데이트
        await updateFile(selectedFile.fileId, {
          practiceRecordingLink: inputUrl,
          status: "inProgress",
        });

        setLoading(false);
        navigate("/partnercomplete");
      } catch (error) {
        setLoading(false);
        console.error("연습 완료 중 오류 발생:", error);
        alert("연습 완료에 실패했습니다.");
      }
    } else {
      alert("버튼 체크, 텍스트 파일, 클로바 링크를 모두 제공해주세요.");
    }
  };

  const handleClovaRecordingClick = () => {
    setClovaRecordingClicked(true);
  };

  const handlePracticeStartedClick = () => {
    setPracticeStartedClicked(true);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {fileData && (
            <div className={styles.customContainer}>
              <p className={styles.p1Text}>연습 예약 정보</p>
              <FileList
                files={partnerFiles.filter(file => file.fileId === selectedFile.fileId)}
                disabled={true}
                style={{ pointerEvents: "none" }} // 클릭 이벤트를 차단하여 비활성화
                onFileClick={() => {}} // 빈 함수 전달
              />

              <p className={styles.p2Text}>연습 자료</p>
              <div className={styles.textContainer}>
                <p className={styles.p3Text}>클로바 녹음을 시도하였습니까?</p>
                <ResponsiveContainer
                  className={styles.customButton}
                  onClick={handleClovaRecordingClick}
                  style={{ backgroundColor: clovaRecordingClicked ? "#D1D6D6" : "" }}
                >
                  <p className={styles.customButtonText}>네</p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textContainer}>
                <p className={styles.p4Text}>연습 시작하였나요?</p>
                <ResponsiveContainer
                  className={styles.customButton2}
                  onClick={handlePracticeStartedClick}
                  style={{ backgroundColor: practiceStartedClicked ? "#D1D6D6" : "" }}
                >
                  <p className={styles.customButtonText2}>{fileData.practiceCallNumber}</p>
                </ResponsiveContainer>
                <ResponsiveContainer
                  className={styles.customButton}
                  onClick={handlePracticeStartedClick}
                  style={{ backgroundColor: practiceStartedClicked ? "#D1D6D6" : "" }}
                >
                  <p className={styles.customButtonText}>네</p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textContainer}>
                <p className={styles.p5Text}>클로바 파일</p>
                <ResponsiveContainer
                  className={styles.fileButton}
                  onDrop={handleFileDrop}
                  onDragOver={handleFileDragOver}
                >
                  <input
                    className={styles.uploadInput}
                    value={inputTxtFile ? inputTxtFile.name : "txt 파일을드래그해주세요"}
                    placeholder="txt 파일을드래그해주세요"
                    readOnly
                  />
                </ResponsiveContainer>

                <ResponsiveContainer>
                  <label htmlFor="fileInput">
                    <p className={styles.customButtonText3}>pc에서 찾기</p>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleTxtFileChange}
                    className={styles.fileInputHidden}
                  />
                </ResponsiveContainer>
              </div>

              <div className={styles.textContainer}>
                <p className={styles.p6Text}>클로바 링크</p>
                <ResponsiveContainer>
                  <input
                    type="text"
                    value={inputUrl}
                    onChange={handleUrlChange}
                    placeholder="암호 해제한 클로바 링크를 적어주세요"
                    className={styles.linkInput}
                  />
                </ResponsiveContainer>
              </div>

              <ResponsiveContainer
                width={120}
                className={styles.customOkButton}
                onClick={handleFinishPractice}
                style={{
                  backgroundColor: practiceFinishedClicked ? "#13CDBE" : "",
                }}
              >
                <p
                  className={styles.customOkButtonText}
                  style={{
                    color: practiceFinishedClicked ? "white" : "",
                  }}
                >
                  연습 마치기
                </p>
              </ResponsiveContainer>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CompletePractice;
