// CustomDatePicker.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import styles from "./CustomDatePicker.module.css";

// 날짜만 선택할 수 있는 DatePicker
const CustomDatePicker = ({ selectedDate, onChange }) => {
  const today = new Date();
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );

  // 날짜 렌더링 커스터마이즈
  const renderDayContents = (day, date) => {
    const isToday = date.toDateString() === today.toDateString();
    const isPast = date < today;

    if (isToday) {
      return (
        <div
          className={styles.disabledDay}
          onClick={() => alert("내일부터 예약 가능합니다.")}
        >
          {day}
        </div>
      );
    }

    if (isPast) {
      return (
        <div
          className={styles.disabledDay}
          onClick={() => alert("예약이 불가능합니다.")}
        >
          {day}
        </div>
      );
    }

    return <div>{day}</div>;
  };

  return (
    <DatePicker
      className={styles.CustomDatePicker}
      selected={selectedDate}
      onChange={onChange}
      dateFormat="yyyy-MM-dd" // 날짜 형식 설정
      placeholderText="날짜를 선택해주세요"
      minDate={tomorrow}
      renderDayContents={(day, date) => renderDayContents(day, date)} // 날짜 커스터마이즈
    />
  );
};

export default CustomDatePicker;
