import React, { createContext, useState, useContext, useEffect } from "react";
import {
  createPracticeInService,
  getPracticeInService,
  updatePracticeInService,
  deletePracticeInService,
  getPracticesByUserId,
} from "../services/practiceService";
import { PracticeModel } from "../models/PracticeModel";
import { useUser } from "./UserContext";

const PracticeContext = createContext();

export const PracticeProvider = ({ children }) => {
  const [practices, setPractices] = useState([]); // 사용자 연습 목록
  const [teamPractices, setTeamPractices] = useState([]);
  const { userData } = useUser();

  // 사용자의 모든 연습 데이터를 초기화합니다.
  useEffect(() => {
    const initializePractices = async () => {
      try {
        if (userData && userData.userId) {
          // Firestore에서 userId와 연관된 연습 데이터 가져오기
          const practicesFromDB = await getPracticesByUserId(userData.userId);
          setPractices(practicesFromDB);
          // 추가: 팀원들의 연습 데이터 병합
          const teamPracticesData = await Promise.all(
            (userData.teamMembers || []).map((member) =>
              member.userId ? getPracticesByUserId(member.userId) : []
            )
          );
          setTeamPractices(teamPracticesData.flat());
        } else {
          setPractices([]);
          setTeamPractices([]);
        }
      } catch (error) {
        console.error("연습 데이터 초기화 오류:", error);
        setPractices([]);
        setTeamPractices([]);
      }
    };

    initializePractices();
  }, [userData]); // userData가 변경될 때마다 초기화

  // 특정 userId로 연습 데이터를 가져오는 함수
  const getPracticesByUser = async (userId) => {
    try {
      return await getPracticesByUserId(userId); // Firestore에서 데이터 가져오기
    } catch (error) {
      console.error("userId로 연습 데이터 조회 오류:", error);
      return [];
    }
  };

  // 새로운 연습 데이터를 생성하고 목록에 추가합니다.
  const createPractice = async (practiceData) => {
    const newPractice = new PracticeModel({
      ...practiceData,
      userId: userData.userId, // 현재 로그인한 사용자의 userId 포함
    });
    try {
      await createPracticeInService(newPractice);
      setPractices((prevPractices) => [...prevPractices, newPractice]);
    } catch (error) {
      console.error("연습 데이터 추가 오류:", error);
    }
  };

  // 특정 연습 데이터를 읽습니다.
  const getPractice = async (practiceId) => {
    try {
      const practice = await getPracticeInService(practiceId);
      return practice;
    } catch (error) {
      console.error("연습 데이터 가져오기 오류:", error);
    }
  };

  // 연습 데이터를 업데이트하고 목록을 갱신합니다.
  const updatePractice = async (practiceId, updatedData) => {
    try {
      await updatePracticeInService(practiceId, updatedData);
      setPractices((prevPractices) =>
        prevPractices.map((practice) =>
          practice.practiceId === practiceId
            ? new PracticeModel({ ...practice, ...updatedData })
            : practice
        )
      );
    } catch (error) {
      console.error("연습 데이터 수정 오류:", error);
    }
  };

  // 연습 데이터를 삭제하고 목록에서 제거합니다.
  const deletePractice = async (practiceId) => {
    try {
      await deletePracticeInService(practiceId);
      setPractices((prevPractices) =>
        prevPractices.filter((practice) => practice.practiceId !== practiceId)
      );
    } catch (error) {
      console.error("연습 데이터 삭제 오류:", error);
    }
  };

  return (
    <PracticeContext.Provider
      value={{
        practices,
        getPracticesByUser,
        teamPractices,
        createPractice,
        getPractice,
        updatePractice,
        deletePractice,
      }}
    >
      {children}
    </PracticeContext.Provider>
  );
};

export const usePractices = () => useContext(PracticeContext);
