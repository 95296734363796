import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ReservationComplete.module.css';
import AlertCard from '../../components/AlertCard/AlertCard';
import CheckImage from '../../assets/images/complete/Check.png';
import NoImage from '../../assets/images/complete/No.png';
import PeopleImage from '../../assets/images/complete/People.png';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

const ReservationComplete = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { status, practiceDateTime } = location.state || {};

    const dateObj = status === 'complete' && practiceDateTime ? new Date(practiceDateTime) : null;

    const year = dateObj ? dateObj.getFullYear() : null;
    const month = dateObj ? String(dateObj.getMonth() + 1).padStart(2, '0') : null;
    const day = dateObj ? String(dateObj.getDate()).padStart(2, '0') : null;
    const hours = dateObj ? String(dateObj.getHours()).padStart(2, '0') : null;
    const minutes = dateObj ? String(dateObj.getMinutes()).padStart(2, '0') : null;

    const displayInfo = {
        complete: [CheckImage, '예약 완료', `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분에 연습 전화가 갈 예정입니다`],
        needSubscription: [NoImage, '구독 회원이 아닙니다', '예약을 위해 구독을 진행해주세요'],
        needLogin: [PeopleImage, '로그인이 필요해요', '로그인하고 AI 피드백과 함께 영업 연습을 시작하세요!']
    }

    const handleConfirmClick = () => {
        if (status === 'needSubscription') {
            navigate('/purchase')
        } else if (status === 'needLogin') {
            navigate('/login')
        } else {
            navigate('/');
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.mainContainer}>
                <ResponsiveContainer width={880} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AlertCard icon={displayInfo[status][0]} title={displayInfo[status][1]} description={displayInfo[status][2]} onConfirm={handleConfirmClick} />
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ReservationComplete;
