import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PartnerMain.module.css";
import { useFiles } from "../../contexts/FileContext";
import FileList from "./../../components/FileList/FileList";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";

const PartnerMain = () => {
  const navigate = useNavigate();
  const { partnerFiles } = useFiles();

  const handleFileClick = (file) => {
    if (file.status === "complete") {
      navigate("/feedback", { state: { selectedFile: file } });
    } else {
      navigate("/completepractice", { state: { selectedFile: file } });
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
        <ResponsiveContainer width={880}>
          <div className={styles.header}>
            <p className={styles.practiceListText}>내 예약</p>
          </div>
          <FileList files={partnerFiles} onFileClick={handleFileClick} />
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PartnerMain;
