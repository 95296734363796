import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 

import styles from './ViewPractice.module.css';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}

function ViewPractice() {
  const navigate = useNavigate();
  const { state } = useLocation(); // 네비게이션 상태에서 데이터 가져오기
  const { selectedPractice } = state; // 반드시 전달받아야 한다고 가정
  
  return (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
      <ResponsiveContainer width={880}>
       
          <h2 className={styles.TitleText}>직접 추가한 연습</h2>

          <div className={styles.inputContainer}>
            <p className={styles.p2Text}>날짜</p>
            <input
              type="text"
              className={styles.Input}
              value={formatDate(selectedPractice.addPracticeDate)}
              readOnly
            />
          </div>
          <div className={styles.inputContainer}>
            <p className={styles.p2Text}>제목</p>
            <input
              type="text"
              className={styles.Input}
              value={selectedPractice.title}
              readOnly
            />
          </div>
          <div className={styles.inputContainer}>
            <p className={styles.p2Text}>내용</p>
            <input
              type="text"
              className={styles.Input}
              value={selectedPractice.content}
              readOnly
            />
          </div>

          <button
            className={styles.addButton}
            onClick={() => navigate('/practice-check')}
          >
            확인
          </button>
          </ResponsiveContainer>
        </div>
      </div>
  );
}

export default ViewPractice;
