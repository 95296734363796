import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext"; // UserContext에서 사용자 정보 가져오기
import styles from "./AddPracticeComplete.module.css";
import CheckButton from "../../assets/images/Check.png";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";

function AddPracticeComplete() {
  const navigate = useNavigate();
  const { userData } = useUser(); // UserContext에서 사용자 정보 가져오기

  // userData가 변경될 때마다 콘솔 로그 출력
  useEffect(() => {
    if (userData) {
      console.log("userData:", userData);
    }
  }, [userData]); // userData가 변경될 때마다 실행

  const goToPracticeCheck = () => {
    navigate("/practice-check");
  };

  if (!userData) {
    return <div>로딩 중...</div>; // userData가 없을 경우 로딩 중 표시
  }

  return (
    <div className={styles.container}>
      <div className={styles.customContainer}>
        <ResponsiveContainer className={styles.CheckButtonContainer}>
          <img
            className={styles.CheckButtonImg}
            src={CheckButton}
            alt="checkbutton Icon"
          />
        </ResponsiveContainer>
        <p className={styles.p1Text}>추가 완료!</p>
        <p className={styles.p2Text}>진행했던 연습 추가가 완료되었어요</p>
        <ResponsiveContainer
          height={48}
          width={448}
          className={styles.customButton}
          onClick={goToPracticeCheck}
        >
          <p className={styles.customButtonText}>확인</p>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default AddPracticeComplete;
