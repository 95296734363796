import React, { useEffect, useState } from "react";
import styles from "./Tooltip.module.css";

const Tooltip = ({ message, targetRef }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - 25, // Adjust tooltip height
        left: rect.left + rect.width / 2,
      });
    }
  }, [targetRef]);

  return (
    <div
      className={styles.tooltip}
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      {message}
    </div>
  );
};

export default Tooltip;
