import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext"; // UserContext에서 사용자 정보 가져오기
import styles from "./LoginComplete.module.css";
import CheckButton from "../../assets/images/Check.png";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import ReactGA from "react-ga4"; // GA 라이브러리 추가

function LoginComplete() {
  const navigate = useNavigate();
  const { userData } = useUser(); // UserContext에서 사용자 정보 가져오기

  // userData가 변경될 때마다 콘솔 로그 출력
  useEffect(() => {
    if (userData) {
      console.log("userData:", userData);
    }
  }, [userData]); // userData가 변경될 때마다 실행

  const goToMain = () => {
    // 버튼 클릭 시 GA 이벤트 전송
    ReactGA.event({
      category: "User",
      action: "Clicked Confirm Button",
      label: "LoginComplete Page",
    });
    console.log("GA Event Sent: Clicked Confirm Button");

    navigate("/main"); // 메인 페이지로 이동
  };

  if (!userData) {
    return <div>로딩 중...</div>; // userData가 없을 경우 로딩 중 표시
  }

  return (
    <div className={styles.container}>
      <div className={styles.customContainer}>
        <ResponsiveContainer className={styles.CheckButtonContainer}>
          <img
            className={styles.CheckButtonImg}
            src={CheckButton}
            alt="checkbutton Icon"
          />
        </ResponsiveContainer>
        <p className={styles.p1Text}>회원가입 완료!</p>
        <p className={styles.p2Text}>이제 서비스를 이용해볼까요?</p>
        <ResponsiveContainer
          height={48}
          width={448}
          className={styles.customButton}
          onClick={goToMain}
        >
          <p className={styles.customButtonText}>확인</p>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default LoginComplete;
